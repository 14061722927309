<template>
    <div>

        <donation-news-form code="소식받기"/>

        <div class="form--primary">
            <form-row tit="이름" pointer>
                <v-text-field v-model="news.name" placeholder="이름을 입력하세요." outlined hide-details />
            </form-row>
            <form-row tit="연락처" pointer>
                <v-row class="row--x-small">
                    <v-col cols="4" lg="auto">
                        <v-select :items="prependPhones" v-model="news.phone1" outlined hide-details="" class="w-lg-200px"/>
                    </v-col>
                    <v-col cols="8" lg="">
                        <v-text-field v-model="news.phone2" placeholder="‘-’를 제외하고 입력하세요." outlined hide-details />
                    </v-col>
                </v-row> 
            </form-row>
            <form-row tit="이메일" pointer>
                <v-row align="center" class="row--x-small">
                    <v-col>
                        <v-text-field v-model="email1" v-bind="$attrs" @input="() => emailJoin()" placeholder="이메일을 입력하세요." outlined hide-details/>
                    </v-col>
                    <v-col cols="auto">@</v-col>
                    <v-col>
                        <v-text-field v-model="email2" v-bind="$attrs" @input="() => emailJoin()" outlined hide-details/>
                    </v-col>
                    <v-col cols="12" md="">
                        <v-select v-model="email3" v-bind="$attrs" persistent-hint :items="prependEmails" item-text="text" item-value="value" @input="email2 = email3" outlined hide-details/>
                    </v-col>
                </v-row>
            </form-row>
            <form-row tit="소식내용" pointer>
                <v-textarea v-model="news.contents" rows="9" placeholder="내용을 입력하세요." outlined hide-details />
            </form-row>
            <form-row tit="첨부파일">
                <v-file-input v-model="file" placeholder="파일을 첨부하세요." outlined hide-details />
            </form-row>
        </div>

        <div class="form__agreement">
            <v-checkbox v-model="agree1" label="개인정보 수집·이용 동의" value="개인정보 수집·이용 동의" hide-details></v-checkbox>
            <button onclick="window.open('/privacy-policy', '', 'width=483,height=700');" class="form__agreement__btn">전문보기</button>
        </div>

        <div class="v-btn--group">
            <v-btn large @click="submit" color="primary" class="min-w-140px min-w-lg-160px">신청하기</v-btn>
        </div>

        <!-- <h1>{{code}}</h1>
        <div>
            <v-card-title>후원방법</v-card-title>
            <v-row>
                <v-col>신청구분</v-col>
                <v-col>{{code}}</v-col>
            </v-row>
            <v-row>
                <v-col>이름 *</v-col>
                <v-col><v-text-field v-model="news.name"/></v-col>
            </v-row>
            <v-row>
                <v-col>연락처</v-col>
                <v-col><v-select :items="prependPhones" v-model="news.phone1"/><v-text-field v-model="news.phone2"/></v-col>
            </v-row>
            <v-row>
                <v-col>이메일</v-col>
                <v-col>
                    <v-text-field v-model="email1" v-bind="$attrs" placeholder="이메일" outlined hide-details @input="() => emailJoin()"/>
                </v-col>
                <v-col cols="auto">@</v-col>
                <v-col>
                    <v-text-field v-model="email2" v-bind="$attrs" outlined hide-details @input="() => emailJoin()"/>
                </v-col>
                <v-col cols="auto">-</v-col>
                <v-col>
                    <v-select v-model="email3" v-bind="$attrs" persistent-hint :items="prependEmails" item-text="text" item-value="value" outlined hide-details @input="email2 = email3"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col>소식내용</v-col>
                <v-col><v-text-field v-model="news.contents" label="내용을 입력하세요."/></v-col>
            </v-row>
            <v-row>
                <v-col>첨부파일</v-col>
                <v-col><v-file-input v-model="file" /></v-col>
            </v-row>
        </div>

        <div>
            <v-card-title>개인정보 수집 및 이용 동의</v-card-title>
            <v-radio-group v-model="agree1">
                <v-radio label="동의함" :value="true"/>
                <v-radio label="동의안함" :value="false"/>
            </v-radio-group>
        </div>

        <div>
            <v-row>
                <v-col><v-btn @click="this.$router.go(-1)">취소</v-btn></v-col>
                <v-col><v-btn @click="submit">신청하기</v-btn></v-col>
            </v-row>
        </div> -->

    </div>
</template>

<script>
import FormRow from "@/components/client/sub/form-row.vue";
import api from "@/api";

export default {
    components:{
        FormRow,
    },
    props:{
        code: String
    },
    data() {
        return {
            agree1: false,

            code: null,
            file: null,
            news: {
                name: null,
                phone: null,
                phone1: "010",
                phone2: null,
                email: null,
                contents: null,
            },

            email1: null,
            email2: null,
            email3: "",
            prependPhones: [
                { text: "010", value: "010" },
                { text: "011", value: "011" },
                { text: "016", value: "016" },
                { text: "017", value: "017" },
                { text: "019", value: "019" }
            ],
            prependEmails: [
                { text: ":: 직접입력 ::", value: "" },
                { text: "naver.com", value: "naver.com" },
                { text: "gmail.com", value: "gmail.com" },
                { text: "daum.net", value: "daum.net" },
                { text: "hanmail.net", value: "hanmail.net" },
            ],
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        async init(){
        },
        validate(){
            try{
                let news = this.news
                if(!news.name) throw new Error('이름을 입력해주세요')
                if(!news.phone || !news.phone1 || !news.phone2) throw new Error('연락처를 입력해주세요')
                if(!news.email || !this.email1 || !this.email2) throw new Error('이메일을 입력해주세요')
                if(!news.contents) throw new Error('소식 내용을 입력해주세요')
                if(!this.agree1) throw new Error('개인정보 수집·이용에 동의해주세요')
                
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async submit(){
            if(this.validate()){
                let news = this.news
                news.address = news.address1 +news.address2
                // let { success } = await api.v1.message.post({ demo })
                let { success, form: { _id } } = await api.v1.forms.post({ 
                    code : this.$props.code,
                    news: this.news,
                    file: this.file
                })
                let _form = _id
                if(this.file) await api.v1.forms.files.post({ _form, file: this.file });

                if(success){
                    alert('소식받기 신청이 완료됐습니다.')
                    // this.$router.push('/')
                }
            }
        },
        emailJoin(){
            this.news.email = `${this.email1}@${this.email2}`
        },
    },
    watch:{
        "news.phone1"(){
            this.news.phone = this.news.phone1 + this.news.phone2
        },
        "news.phone2"(){
            this.news.phone = this.news.phone1 + this.news.phone2
        },
        email3(){
            this.emailJoin()
        },
    }
}
</script>

<style>

</style>