var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-row"
  }, [_c('v-row', {
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-n26 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('div', {
    staticClass: "pa-12 py-md-16 px-md-20"
  }, [_c('p', {
    staticClass: "page-text grey-1--text"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " "), _vm.pointer ? _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("*")]) : _vm._e()])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('div', {
    staticClass: "pa-12 py-md-16 px-md-20"
  }, [_vm._t("default")], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }