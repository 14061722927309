<template>
    <client-page>
        <sub-visual
        sh="소통과 참여"
        tabActive="위드 캔 소식받기"
        bg="/images/sub/visual/sv5.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <donation-news-form code="소식받기"/>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import DonationNewsForm from "@/components/client/support/donation-news-form.vue"

export default {
    components: {
        ClientPage,
        SubVisual,
        DonationNewsForm
    },
    data() {
        return {
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        async init(){
        }
    },
    watch:{
        code(){
        }
    }
}
</script>

<style>

</style>